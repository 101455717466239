<template>
  <div style="background-color: #ffffff;">
    <my-loading />
    <div class="join"> 
        加入我们
    </div>
    <img :src="requestUrl + 'platform/' + item.fileUrl" alt="" class="big" v-for="(item, index) in lists" :key="index"/>
    </div>
</template>
<script>
import { getJoin, requestUrl } from "server/test";
import MyLoading from '../../../components/common/myLoading.vue';
export default {
  name: "join",
  components: {MyLoading},
  props: {},
  data() {
    return {
      ifPlay: true,
      lists:[],
      requestUrl: requestUrl,
    };
  },
  created() {},
  computed: {},
  mounted() {
      this.getLists()
  },
  watch: {},
  methods: {
      async getLists(e) {
      try {
        let res = await getJoin();
        this.lists.push(...res.data);
      } catch (error) {}
    },
    play(e) {
      this.ifPlay = false;
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
  },
};
</script>
<style scoped>
    .join{
        width: 90%;
        margin-left: 5%;
        font-size: 25px;
        font-weight: 500;
        margin-top:180px;
        padding-bottom:50px;
        border-bottom:1px solid black;
        
    }
    .big{
        width: 90%;
        margin-left: 5%;
    }
</style>