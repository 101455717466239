<template>
  <div style="background-color: #000;">
    <my-icon />
    <my-loading/>
    <my-section :topShow="false" :listColor="'white'"/>
    <div class="main">
      <img src="@image/about/about1.jpg" alt="" class="big" id="lampolook" @click="toPage('lampolook')">
      <img src="@image/about/about2.jpg" alt="" class="big" id="onelanalook" @click="toPage('onelanalook')">
      <img src="@image/about/about3.jpg" alt="" class="big" id="sewavinglook" @click="toPage('sewavinglook')">
    </div>
    <my-footer />
  </div>
</template>
<script>
import MyFooter from '../../components/common/myFooter.vue';
import MyLoading from '../../components/common/myLoading.vue';
export default {
  name: "about",
  components: {
    MyLoading,
    MyFooter
  },
  props: {
  },
  data() {
    return {
    };
  },
  created(){
  },
  computed: {
   
  },
  mounted() {
    if (this.$route.query.from) {
      this.$nextTick(() => {
        setTimeout(()=>{
          document.getElementById(this.$route.query.from).scrollIntoView(true);
        },100)
      });
    }
  },
  watch: {

  },
  methods: {
    toPage(e){
      this.jump(e)
    }
  }
};
</script>
<style scoped>
  .main{
    width: 100vw;
    background:#a3a4a5;
  }
  .big{
    width: 100%;
    cursor: pointer;
  }
 
</style>