import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/home/home'
import about from '@/views/home/about'
import main from './main'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
      meta: {
        title: '首页',
        isAlive: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: about,
      meta: {
        title: '品牌介绍',
        isAlive: false,
        
      },
    },
    main
  ]
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // if (to.path === '/login') next()
  // const tokenStr = window.sessionStorage.getItem('token')
  // // 增加判断条件
  // if (!tokenStr && to.path !== '/login') return next('/login')
  next()
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
