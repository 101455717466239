import * as types from './types'
export default{
		[types.CHANGECITY](state,city){
			state.city = city
			// try{
			localStorage.city = city
			// }catch(e){

			// }
		},
		[types.BIGCITY](state,city){
			state.city = city+1
			localStorage.city = city
		},
		[types.CHANGELOADING](state,res){
			state.isLoading = res;
		},
		[types.CHANGESTATE](state,res){
			state.loadingState= res;
		},

}

	