<template>
  <div>
      <my-loading />
    <!-- <div class="join">
      <div>及时得到最新资讯</div>
      <div>订阅蓝豹电子报，探索我们的故事、产品和每一次惊喜。</div>
    </div> -->
    <img :src="requestUrl + 'platform/' + item.fileUrl" alt="" class="big" v-for="(item, index) in lists" :key="index"/>
    <!-- <div class="lists">
      <div class="listItem" v-for="(item, index) in lists" :key="index">
        <div class="top">
          <img :src="requestUrl + 'platform/' + item.fileUrl" alt="" />
        </div>
        <div class="myText">
          <div class="middle">
            {{ item.title }}
          </div>
          <div class="bottom" v-html="item.content"></div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { getNews, requestUrl } from "server/test";
export default {
  name: "join",
  components: {},
  props: {},
  data() {
    return {
      ifPlay: true,
      lists: [],
      requestUrl: requestUrl,
    };
  },
  created() {
  },
  computed: {},
  mounted() {
    this.getLists();
  },
  watch: {},
  methods: {
    async getLists(e) {
      try {
        let res = await getNews();
        this.lists.push(...res.data);
      } catch (error) {}
    },
    play(e) {
      this.ifPlay = false;
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
  },
};
</script>
<style scoped>
.join {
  width: 90%;
  margin-left: 5%;
  font-size: 28px;
  margin-top: 100px;
  padding-bottom: 50px;
  text-align: center;
}
.join > div:nth-child(1) {
  font-weight: 600;
}
.join > div:nth-child(2) {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 500;
}
.big{
  width: 100%;
}
.lists {
  min-width: 1200px !important;
  margin-left: 5%;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.listItem {
  width: 31%;
  padding-bottom: 40%;
  margin-top: 50px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 500;
  position: relative;
  border: 1px solid #999;
}
.top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 45%;
  overflow: hidden;
}
.top img {
  width: 100%;
}
.myText{
    position: absolute;
    top:55%;
    width: 100%;
}
.middle {
  width: 65%;
  margin-left: 17.5%;
  min-height: 100px;
  text-align: center;
  font-size: 0.55rem;
  line-height: 30px;
  font-weight: 600;
}
.bottom {
  width: 90%;
  margin-left: 5%;
  text-align: center;
  font-size: 0.3rem;
  line-height: 20px;
  font-weight: 600;
}
</style>