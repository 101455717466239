import lampolook from '@/views/lampoSite/sonSite/lampolook'
import onelanalook from '@/views/lampoSite/sonSite/onelanalook'
import sewavinglook from '@/views/lampoSite/sonSite/sewavinglook'
import group from '@/views/lampoSite/introSite/group'
import joinus from '@/views/lampoSite/introSite/joinus'
import join from '@/views/lampoSite/introSite/join'
import contact from '@/views/lampoSite/introSite/contact'
import news from '@/views/lampoSite/introSite/news'
import store from '@/views/lampoSite/introSite/store'
import main from '@/views/lampoSite/main'
import subView from "@com/common/sub-view.vue"
export default {
    path: '/main',
    name: 'main',
    component: main,
    meta: {
        title: '蓝豹'
    },
    redirect: { name: 'lampo' },
    children: [
        {
            path: '/lampolook',
            name: 'lampolook',
            component: lampolook,
            meta: {
                title: 'lampolook',
                listColor: 'black',
                exc:'exc',
                color: 'white',
                backColor: '#1b2244',
                par:'lampo'
            }
        },
        {
            path: '/onelanalook',
            name: 'onelanalook',
            component: onelanalook,
            meta: {
                title: 'onelanalook',
                listColor: 'one',
                color: 'one',
                backColor: '#d9d9d9',
                par:'lampoone'
            },
        },
        {
            path: '/sewavinglook',
            name: 'sewavinglook',
            component: sewavinglook,
            meta: {
                title: 'sewavinglook',
                listColor: 'gold',
                color: 'gold',
                backColor: '#000',
                isAlive: false,
                par:'lampobespoke'
            },
        },
        {
            path: '/group',
            name: 'group',
            component: group,
            meta: {
                title: 'group',
                color: 'one',
                topShow: false,
                backColor: '#000',
            },
        },
        {
            path: '/joinus',
            name: 'joinus',
            component: joinus,
            meta: {
                title: 'joinus',
                color: 'black',
                topShow: false,
                backColor: '#000',
            },
        },
        {
            path: '/join',
            name: 'join',
            component: join,
            meta: {
                title: 'join',
                color: 'black',
                backColor: '#000',
                topShow: false
            },
        }, 
        {
            path: '/contact',
            name: 'contact',
            component: contact,
            meta: {
                title: 'contact',
                color: 'black',
                topShow: false,
                backColor: '#000',
            },
        }, 
        {
            path: '/news',
            name: 'news',
            component: news,
            meta: {
                title: 'news',
                color: 'one',
                topShow: false,
                backColor: '#000',
            },
        },{
            path: '/store',
            name: 'store',
            component: store,
            meta: {
                title: 'store',
                color: 'one',
                topShow: false,
                ifPad:false,
                backColor: '#000',
            },
        },
    ]
}