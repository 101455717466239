import Vue from 'vue'

// 顶部导航
Vue.component('mySection', () => import('./common/mySection'))

// 底部
Vue.component('myFooter', () => import('./common/myFooter'))

//加载
Vue.component('myLoading', () => import('./common/myLoading'))

//返回
Vue.component('myIcon', () => import('./common/myIcon'))