<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- <div v-show="isLoading">哈哈撒哈哈哈哈哈</div> -->
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Overlay, Loading } from "vant";
Vue.use(Overlay).use(Loading);
export default {
  name: "app",
  data() {
    return {};
  },
  created() {
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoading"])
  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1rem;
  height: 1rem;
  z-index: 99999;
}
.el-popper,.el-cascader__dropdown{
    background: black !important;
    border: 1px solid #bea681 !important;
}
.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #bea681 !important;
}
.el-popper[x-placement^=top] .popper__arrow::after {
    border-top-color: #bea681 !important;
}
.el-cascader-node{
  color:#bea681 !important;
  font-family: 'hei35';
}
.el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
    border:1px solid #bea681 !important;
    background:none !important;
}
.el-cascader-menu {
    border-right: solid 1px #bea681 !important;
}
.el-cascader-node {
    padding: 0 20px 0 40px !important;
    width: 240px !important;
}
.el-date-table td.disabled div {
    background-color: #000 !important;
    color:#666  !important
}
.el-date-table td div {
    color:#eee !important
}
::-webkit-scrollbar
{
width: 5px;
}
::-webkit-scrollbar-track {
border-radius: 10px;
background-color: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: rgba(0,0,0,0.1);
}
</style>
