<template>
  <div>
    <div class="top">
      <img
        src="@image/play.png"
        alt=""
        class="play"
        @click="play"
        v-if="ifPlay&&nowLists.viedoUrl"
      />
      <video
        :src="requestUrl + 'platform/' + nowLists.viedoUrl"
        :poster="requestUrl + 'platform/' + nowLists.picUrl[0]"
        class="myvideo"
        ref="myvideo"
        v-if="nowLists.viedoUrl"
      ></video>
    </div>
    <div class="middle">
      <img
        :src="requestUrl + 'platform/' + item"
        alt=""
        v-for="(item, index) in nowLists.picUrl"
        :key="index"
        v-show="index>0"
      />
    </div>
  </div>
</template>
<script>
import {requestUrl } from "server/test";
export default {
  name: "onelanalook",
  components: {},
  props: {
    nowLists:Object
  },
  data() {
    return {
      ifPlay: true,
      requestUrl: requestUrl,
    };
  },
  created() {},
  computed: {},
  mounted() {
  },
  watch: {},
  methods: {
    play(e) {
      this.ifPlay = false;
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
  },
};
</script>
<style scoped>
.top {
  width: 90%;
  margin-left: 5%;
  padding-top: 80px;
  padding-bottom: 50px;
  position: relative;
}
.play {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-left: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
}
.myvideo {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: cover;
}
.intro{
    font-size: 20px;
    color:#aaa;
    text-align: center;
    margin: 50px 0 100px 0;
    font-family: 'hei';
}
.eng{
    font-family: 'light';
}
.intro div:first-child{
    font-size: #a3a4a5;
    margin-bottom: 50px;
}
.intro div:nth-child(2){
    margin-bottom:20px
}

.middle {
  width: 100%;
  min-height: 800px;
}
.middle img {
  width: 100%;
  padding-bottom:100px
}
.middle img:last-child{
    padding-bottom: 30px;
}
</style>