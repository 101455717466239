<template>
  <div>
    <!-- <div class="title"> 
        <div>LAMPO</div>
        <div>
            LAMPO创立于1993年，以男装的品质革新为支点，持续升级面料与工艺<br/>
            逐步融入创新美学的理念，解析当代艺术质感。
        </div>
    </div> -->
    <img src="@image/join/j1.jpg" alt="" style="width:100%"/>
    <div class="middle" @click="toPage('/join')">
      <img src="@image/join/j2.jpg" alt="" />
      <img src="@image/join/j3.jpg" alt="" />
      <img src="@image/join/j4.jpg" alt="" />
      <img src="@image/join/j5.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "lampolook",
  components: {},
  props: {},
  data() {
    return {
      ifPlay: true,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    play(e) {
      this.ifPlay = false;
      console.log(121);
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
    toPage(e){
        this.jump(e)
    }
  },
};
</script>
<style scoped>
.title{
    margin-top:100px;
    text-align: center;
}
.title>div:first-child{
    font-size: 45px;
    font-weight: 600;
    font-family:'puhuibold';
    letter-spacing: 2px;
}
.title>div:nth-child(2){
    margin-top:35px;
    font-size: 20px;
    line-height: 35px;
    color:#a3a4a5
}
.top {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
  position: relative;
}
.play {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-left: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
}
.myvideo {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: cover;
}
.middle {
  width: 100%;
  min-height: 600px;
  
}
.middle img {
  width: 100%;
  cursor: pointer;
}
</style>