<template>
  <div class="storeMain">
  <my-loading />
    <div class="title">
      <div>门店查询</div>
      <div class="city">
        <!-- 上海<i class="el-icon-arrow-down el-icon--right"></i> -->
        <el-dropdown
              placement="bottom-start"
              @command="handleCommand"
              trigger="click"
            >
              <span class="el-dropdown-link">
                {{ cityName }} <span class="iconfont icon-xia"></span
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in citys"
                  :key="index"
                  :command="item.cityCode + '-' + item.cityName"
                  >{{ item.cityName }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
      </div>
    </div>
    <div class="lists" :class="{listsShow:storeLists.length<1}">
      <div class="listItem" v-for="(item,index) in storeLists" :key="index">
        <div class="item">
          <div>
            <div></div>
            <div>{{item.storeName}}</div>
          </div>
          <div>
            <div>{{item.address}}</div>
            <div>{{item.mobile}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStoreList } from "server/test";
import MyLoading from '../../../components/common/myLoading.vue';
export default {
  name: "store",
  components: {MyLoading},
  props: {},
  data() {
    return {
      ifPlay: true,
      lists:[],
      cityName:'上海',
      citys:[],
      storeLists:[]
    };
  },
  created() {},
  computed: {},
  mounted() {
      this.getStore()
  },
  watch: {},
  methods: {
      async getStore(e) {
      try {
        let res = await getStoreList();
        let arr = []
        arr.push(...res.data)
        this.lists.push(...res.data);
        this.handleChangeLists(this.cityName)
        arr.map(item=>{
          let temp = {
            cityCode:item.value,
            cityName:item.label=='日照市'?'日照':item.label
          }
          this.citys.push(temp)
        })
      } catch (error) {}
    },
    handleCommand(data) {
      this.cityCode = data.split("-")[0];
      this.cityName = data.split("-")[1];
      this.handleChangeLists(this.cityName=='日照'?'日照市':this.cityName)
    },
    handleChangeLists(e){
      let name = e
      this.lists.map(item=>{
        if(item.label==name){
          this.storeLists = item.children
        }
      })
    }
  },
};
</script>
<style lang="stylus" scoped>
.storeMain{
  background:#ffffff
}
.storeMain>>>.el-dropdown {
  border: none;
  font-size: 25px;
  color: #000;
  font-weight: 500 !important;
}
.storeMain>>>.el-dropdown-menu,.el-popper{
    max-height: 200px !important;
    overflow: hidden auto !important;
}
.storeMain>>>.el-cascader .el-input .el-icon-arrow-down {
  font-size: 25px;
  margin-left: 30px;
}
.el-popper,.el-cascader__dropdown{
    background: #fff !important;
    border: none !important;
}
.title {
  width: 90%;
  margin-left: 5%;
  font-size: 25px;
  font-weight: 500;
  margin-top: 180px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  
}

.city {
  cursor: pointer;
  margin-right: 15px;
}

.icon-xia {
  margin-left: 30px;
  color: #999;
}

.lists {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.listsShow{
  height:700px
}
.listItem {
  width: 100%;
  height: 150px;
  border-top: 1px solid black;
}

.item {
  width: 100%;
  margin-left: 30%;
  display: flex;
  font-weight: bolder;
  font-size: 15px;
  padding-top: 50px;
}

.item > div:first-child {
  display: flex;
  min-width:200px
}
.item > div:nth-child(2) {
  min-width:400px
}

.item > div:first-child > div:first-child {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
  margin-top: 3.5px;
}

.item > div:first-child > div:nth-child(2) {
  width:200px;
  margin-left: 30px;
}

.item > div:nth-child(2) > div:nth-child(2) {
  margin-top: 20px;
}
.bottom{
    text-align center;
    padding-bottom 50px
}
</style>