<template>
  <div>
    <div class="loadingBack" v-if="isLoading">
      <img src="@image/loading.gif" alt="" class="loading" v-if="isLoading">
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "myLoading",
  components: {

  },
  props: {
  },
  data() {
    return {
        isLoading:true
    };
  },
  created(){
  },
  computed: {
   
  },
  mounted() {
      setTimeout(()=>{
          this.isLoading = false
      },2000)
  },
  watch: {

  },
  methods: {
    
  }
};
</script>
<style scoped>
    .loadingBack{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        z-index: 99;
        background:#ffffff;
    }
    /* .loadingHide{
      height: 0 !important;
    } */
    .loading{
      width: 150px;
      height: 85px;
      position: absolute;
      top:50%;
      left: 50%;
      margin-left:-96px;
      margin-top:-54px
    }
    .section{
      widows: 150px;
      height: 150px;
    }
</style>