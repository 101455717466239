
import axios from 'axios'
// import qs from 'qs'
import { Message  } from 'element-ui';
import store from '../store/index'
axios.interceptors.response.use(response => {
  setTimeout(() => {3
    console.log(12312)
    store.commit('changeLoading',false)
  }, 1000);
  if(response.data.data){
    // console.log(response.data.data)
  }else if(response.data.result != 'true'){
    Message({message: response.data.message,type:'warning'})
  }
  return response
}, err => {
  setTimeout(() => {
    store.commit('changeLoading',false)
  }, 1000);
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '错误请求'
        break;
      case 401:
          // dd.env.platform=="notInDingTalk"?window.location.replace(getRedirectUrl(window.location.href)):window.location.reload();
          err.message = ''
        break;
      case 403:
        err.message = '拒绝访问'
        break;
      case 404:
        err.message = '请求错误,未找到该资源'
        break;
      case 405:
        err.message = '请求方法未允许'
        break;
      case 408:
        err.message = '请求超时'
        break;
      case 500:
        err.message = '服务器端出错'
        break;
      case 501:
        err.message = '网络未实现'
        break;
      case 502:
        err.message = '网络错误'
        break;
      case 503:
        err.message = '服务不可用'
        break;
      case 504:
        err.message = '网络超时'
        break;
      case 505:
        err.message = 'http版本不支持该请求'
        break;
      default:
        err.message = `连接错误${err.response.status}`
    }
  } else {
    err.message = '连接到服务器失败';
  }
  if(err.message) Message({message: err.message,type:'warning'});
    return Promise.resolve(err.response)
})
export default async (url = '', data = {}, method = 'get', config = {headers:{}},isLoading=true) => {
  store.commit('changeLoading',true)
  method = method.toLowerCase()
  // let token = window.sessionStorage.getItem("token")?window.sessionStorage.getItem("token"):getCookie('token')?getCookie('token'):''
//   if(getCookie('_dstoken')) {
//     config.headers['Authorization'] = getCookie('_dstoken');
//   }
  let params;
  if(config.headers['Content-Type']=='multipart/form-data'){
    params = new FormData(); //创建form对象
    for(let item of Object.keys(data)){
      // params.append(item,data[item])
      if(Array.isArray(data[item])&&data[item][0].file.constructor===File){
        for(let ite of data[item]){
          let file = ite.file;
          params.append(item,file);//通过append向form对象添加数据
        }
      }else{
        params.append(item,data[item])
      }
    }
  }else{
    params=data
  }
  
  const ajaxConfig = Object.assign({
    url,
    method
  }, config)
  if (['post', 'put', 'patch'].includes(ajaxConfig.method)) {
    ajaxConfig.data = params
  } else {
    ajaxConfig.params = data
  }
  
  let result = (await axios(ajaxConfig)).data
  
  // store.commit('changeLoading',false)
  return result
}
