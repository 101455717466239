<template>
  <div class="container">
    <my-section :topShow="false" :listColor="'white'" :isIndex="true"/>
    <my-loading v-if="loadingShow" />
    <div class="back">
      <ul>
        <li>
          <video
            :src="requestUrl+'platform/'+videoList.lampoViedoUrl"
            autoplay
            muted
            loop
            class="big"
            ref="myvideo0"
            v-show="nowIndex == 0"
             v-if="videoList.lampoViedoUrl"
          ></video>
        </li>
        <li>
          <video
            :src="requestUrl+'platform/'+videoList.oneViedoUrl"
            muted
            loop
            class="big"
            ref="myvideo1"
            v-show="nowIndex == 1"
             v-if="videoList.oneViedoUrl"
          ></video>
        </li>
        <li>
          <video
            :src="requestUrl+'platform/'+videoList.bespokeViedoUrl"
            muted
            loop
            class="big"
            ref="myvideo2"
            v-show="nowIndex == 2"
             v-if="videoList.bespokeViedoUrl"
          ></video>
        </li>
        <!-- <li>
          <video
            :src="requestUrl+'platform/'+videoList.indexViedoUrl"
            autoplay
            muted
            loop
            class="big"
            ref="myvideo3"
            v-show="nowIndex == -1"
             v-if="videoList.indexViedoUrl"
          ></video>
        </li> -->
      </ul>
    </div>
    <div class="cover"></div>
    <div class="change">
      <div @mousemove="change(0)" @mouseleave="changeOri" @click="toPage('lampolook')">
        <img src="@image/home/h1.png" alt=""/>
        <div class="leftClo"></div>
      </div>
      <div @mousemove="change(1)" @mouseleave="changeOri"  @click="toPage('onelanalook')">
        <img src="@image/home/h2.png" alt=""/>
        <div class="leftClo"></div>
      </div>
      <div @mousemove="change(2)" @mouseleave="changeOri" @click="toPage('sewavinglook')">
        <img
          src="@image/home/h3.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getWebViedoList,requestUrl } from "server/test";
import { mapState, mapMutations } from "vuex";
export default {
  name: "home",
  components: {},
  props: {},
  data() {
    return {
      nowIndex: 0,
      loadingShow: true,
      videoList:[],
      requestUrl:requestUrl
    };
  },
  computed: {
    ...mapState(["loadingState"]),
  },
  mounted() {
    this.videoElement = document.getElementsByTagName("video");
    this.allPause(0);
    this.getVideo();
    // 判断首次进入加载
    // if (this.loadingState) {
      this.changeState(false);
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.myvideo0.play();
        }, 2500);
        setTimeout(() => {
          this.loadingShow = false;
        }, 2500);
      });
    // } else {
    //   this.loadingShow = false;
    //   this.$refs.myvideo3.play();
    // }
  },
  watch: {},
  methods: {
    async getVideo() {
      let res = await getWebViedoList();
      this.videoList = res.data[0]
    },
    go() {
      this.jump({ name: "lampolook", params: { id: 1 } });
    },
    change(e) {
      this.$nextTick(() => {
        if (this.nowIndex != e) {
          this.nowIndex = e;
          if (this.nowIndex == 0) {
            this.allPause(0);
            this.$refs.myvideo0.play();
          } else if (this.nowIndex == 1) {
            this.allPause(0);
            this.$refs.myvideo1.play();
          } else if (this.nowIndex == 2) {
            this.allPause(0);
            this.$refs.myvideo2.play();
          }
        }
      });
    },
    changeOri() {
      // this.nowIndex = 1;
      // this.allPause(1);
      // this.$refs.myvideo1.play();
    },
    toPage(e) {
      this.jump({ name: e});
    },
    allPause(index) {
      for (let i = index; i < this.videoElement.length; i++) {
        this.videoElement[i].pause();
      }
    },
    ...mapMutations(["changeState"]),
  },
};
</script>
<style scoped>
.container {
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
}
.back {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1;
}
.back ul {
  display: block;
  width: 100%;
  height: 100%;
}
.back ul li {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.big {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cover {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;
  background: url(http://www.princesky.com/images/overlays/11.png);
}
.change {
  position: fixed;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 3;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.change > div {
  width: 33%;
  height: 100%;
  padding-bottom:80px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.change > div:first-child img {
  height: 25px;
  margin-bottom:15px;
  cursor: pointer;
}
.change > div:nth-child(2) img {
  height: 65px;
  cursor: pointer;
}
.change > div:nth-child(3) img {
  height: 65px;
  cursor: pointer;
}
.leftClo {
  position: absolute;
  right: 0;
  width: 3px;
  height: 20px;
  bottom: 100px;
  background: white;
}
</style>