<template>
  <div>
    <div class="middle">
      <img src="@image/group/g1.jpg" alt="" />
      <img src="@image/group/g2.jpg" alt="" />
      <img src="@image/group/g3.jpg" alt="" />
      <img src="@image/group/g4.jpg" alt="" />
      <img src="@image/group/g5.jpg" alt="" />
      <img src="@image/group/g6.jpg" alt="" />
      <img src="@image/group/g7.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "group",
  components: {},
  props: {},
  data() {
    return {
      ifPlay: true,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    play(e) {
      this.ifPlay = false;
      console.log(121);
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
  },
};
</script>
<style scoped>
.top {
  width: 90%;
  margin-left: 5%;
  padding-top: 80px;
  padding-bottom: 50px;
  position: relative;
}
.middle {
  width: 100%;
  min-height: 800px;
}
.middle img {
  width: 100%;
}
.middle img {
  width: 100%;
  padding-bottom:0
}
</style>