/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-15 17:11:22
 * @LastEditTime: 2019-10-10 22:21:34
 * @LastEditors: Please set LastEditors
 */
import Vue from "vue";
import { Toast,Dialog,ImagePreview } from 'vant';
Vue.use(Toast).use(Dialog).use(ImagePreview)
export default{
    data(){
        return{
            tip:{
                fail:msg=>{
                    Toast.fail(msg);
                },
                success:msg=>{
                    Toast.success(msg);
                }
            },
            
        }
    },
    methods:{
        overView(images,index){
            let imgs;
            if(images[0].content!==undefined){
              imgs = images.map(item=>{
                return item.content
              })
            }else{
              imgs = images
            }
            ImagePreview({
              images:imgs,
              startPosition:index===undefined?0:index,
              loop:false});
        },
        jump(args){
            this.$router.push(args)
        },
        alert({confirmButtonText,title='提示',message,cancelButtonText,showCancelButton=false,confirmFn}){
            Dialog.alert({
                className:'myDialog',
                messageAlign:'left',
                confirmButtonColor:'#fff',
                confirmButtonText,
                cancelButtonText,
                showCancelButton,
                title,
                message
            }).then(() => {
                confirmFn&&confirmFn()
            }).catch(() => {
            // on cancel
            });
        },
        back(){
            history.back()
        },
        formatWeek(index){
            let week;
            switch(index){
                case 1:
                    week='周一';
                    break;
                case 2:
                    week='周二';
                    break;
                case 3:
                    week='周三';
                    break;
                case 4:
                    week='周四';
                    break;
                case 5:
                    week='周五';
                    break;
                case 6:
                    week='周六';
                    break;
                case 7:
                    week='周日';
                    break;
            }
            return week
        },
        formatDate(e){
            if(!e){return}
            let date = new Date(e);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = date.getMonth() + 1 ;
            M<10?M='0'+M:'';
            return Y + M
        },
        formatTim(e){
            if(!e){return}
            let date = new Date(e);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '年';
            let M = date.getMonth() + 1 + '月'
            let D = date.getDate() + '日';
            let H = date.getHours()>9?date.getHours():'0'+date.getHours();
            let m = date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes();
            let s = date.getSeconds()>9?date.getSeconds():'0'+date.getSeconds();
            return Y + M + D +' '+ H +':'+ m+':'+ s
        },
    }
}