
let defaultCity = '上海'
// try{
	if(localStorage.city){
		defaultCity = localStorage.city
	}
let num = 99
// }catch(e){

// }

export default{
		city:defaultCity,
		imgs:[],
		mynum:num,
		name:'',
		isLoading:false,
		loadingState:true
}