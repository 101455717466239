<template>
  <div class="main">
    <my-loading v-if="this.$route.query.from == 'reserve'"/>
    <div class="banner">
      <img
        v-if="nowLists.picUrl"
        :src="requestUrl + 'platform/' + nowLists.picUrl[1]"
        alt=""
      />
    </div>
    <div class="top">
      <img
        src="@image/play.png"
        alt=""
        class="play"
        @click="play"
        v-if="ifPlay && nowLists.viedoUrl"
      />
      <video
        :src="requestUrl + 'platform/' + nowLists.viedoUrl"
        :poster="requestUrl + 'platform/' + nowLists.picUrl[0]"
        class="myvideo"
        ref="myvideo"
        v-if="nowLists.viedoUrl"
      ></video>
    </div>
    <div class="middle">
      <img
        :src="requestUrl + 'platform/' + item"
        alt=""
        v-for="(item, index) in nowLists.picUrl"
        :key="index"
        v-show="index>1"
      />
    </div>
    <div class="bottom" id="reserve">
      <div class="succDiv" v-if="succDiv">
        <img src="@image/sewavinglook/succ.png" alt="">
      </div>
      <div class="title">
        <span class="eng">LAMPO BESPOKE</span>真诚邀请您前往体验西服艺术之旅。<br />
        立即预约一次会面，享受个性化高级定制服务。
      </div>
      <div class="info">
        <div class="section">
          <div>门店</div>
          <div class="shu"></div>
          <div style="margin-top: -5px">
            <span class="iconfont icon-xia"></span>
            <el-cascader
              v-model="value"
              :options="lists"
              @change="handleChange"
              placeholder=""
            ></el-cascader>
          </div>
        </div>
        <div class="section">
          <div>姓名</div>
          <div class="shu"></div>
          <div><input type="text" model="name" v-model="name" /></div>
        </div>
        <div class="section">
          <div>电话</div>
          <div class="shu"></div>
          <div><input type="text" model="phone" v-model="phone" /></div>
        </div>
        <div class="section">
          <div>日期</div>
          <div class="shu"></div>
          <div>
            <el-date-picker
              v-model="date"
              type="date"
              placeholder=""
              :picker-options="pickerOptionsStart"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="btn" @click="confirm">预约一次会面</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getStoreList,
  saveAppointMent,
  getLookBookList,
  requestUrl,
} from "server/test";
export default {
  name: "sewavinglook",
  components: {},
  props: {
    nowLists: Object,
  },
  data() {
    return {
      lists: [],
      requestUrl: requestUrl,
      ifPlay: true,
      name: "",
      phone: "",
      time: "",
      date: new Date(),
      phone: "",
      name: "",
      shopName: "　",
      shopId: "",
      content: "",
      value: " ",
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
          //   return time.getTime() > Date.now() - 8.64e6;
        },
      },
      succDiv:false
    };
  },
  created() {
    if (this.$route.query.from && this.$route.query.from == "reserve") {
      this.$nextTick(() => {
        setTimeout(() => {
          document.getElementById("reserve").scrollIntoView({ block: 'start'});
        }, 1500);
      });
    }
  },
  computed: {},
  mounted() {
    this.getStore();
  },
  watch: {},
  methods: {
    play(e) {
      this.ifPlay = false;
      this.$refs.myvideo.play();
      this.$refs.myvideo.controls = true;
    },
    async getStore(e) {
      try {
        let res = await getStoreList();
        let arr = [];
        let newArr = [];
        arr.push(...res.data);
        console.log(arr)
        arr.map((item) => {
          if(item.label=='日照市'){
            item.label = '日照'
          }
          item.children.map((son) => {
            son.label = son.storeName;
            son.value = son.storeId;
          });
          newArr.push(item);
        });
        this.lists.push(...newArr);
      } catch (error) {
        console.log(error);
      }
    },
    async confirm(e) {
      if (this.shopName.length < 2) {
        this.$message({ message: "请选择门店", type: "warning" });
        return;
      }
      if (this.name.length < 1) {
        this.$message({ message: "请填写您的姓名", type: "warning" });
        return;
      }
      if (this.phone.length != 11) {
        this.$message({ message: "请填写您的电话", type: "warning" });
        return;
      }
      if (this.date.length < 1) {
        this.$message({ message: "请填写日期", type: "warning" });
        return;
      }
      let params = {
        storeId: this.shopId,
        name: this.name,
        mobile: this.phone,
        appointDate:
          new Date(this.date).getFullYear() +
          "-" +
          (new Date(this.date).getMonth() + 1) +
          "-" +
          new Date(this.date).getDate(),
      };
      try {
        let res = await saveAppointMent(params);
        if (res.result == "true") {
          this.succDiv = true
          // this.$nextTick(()=>{
          //   setTimeout(()=>{
          //     this.succDiv = false
          //   },2000)
          // })
          this.shopId = "";
          this.shopName = "　";
          this.value = " ";
          this.name = "";
          this.phone = "";
          this.date = "";
        } else {
          this.$message({ message: res.message, type: "warning" });
        }
      } catch (error) {}
    },
    handleChange(data) {
      this.shopName = this.value[1];
      this.shopId = this.value[1];
    },
  },
};
</script>
<style lang="stylus" scoped>
.main>>>.el-cascader {
  width: 100%;
}

.main>>>.el-input__suffix {
  display: none;
}

.main>>>.el-input__prefix {
  display: none;
}

.main>>>.el-input__inner {
  font-size: 18px;
  height:30px;
  color: #bea681;
  background: none;
  border: none;
  padding-left: 0;
  font-family: 'hei35';
  widows: 500px;
  margin-top:-5px
}
.eng{
  font-family 'beseng'
}
.main>>>.el-date-editor.el-input, .el-date-editor.el-input__inner {
  cursor: pointer;
  width: 600px;
}

.el-dropdown-link {
  font-size: 23px;
  color: #bea681;
  width: 600px;
  display: inline-block;
}
.banner{
  min-height: 600px;
}
.banner img {
  width: 100%;
}

.top {
  width: 90%;
  margin-left: 5%;
  padding-top: 80px;
  padding-bottom: 50px;
  position: relative;
}

.play {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  margin-left: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
}

.myvideo {
  width: 100%;
  height: 100%;
  min-height: 400px;
  object-fit: cover;
}

.middle {
  width: 100%;
  min-height: 800px;
}

.middle img {
  width: 100%;
}

.bottom {
  width: 100%;
  padding-top: 50px;
  text-align: center;
  color: #bea681;
  line-height: 30px;
  font-size: 18px;
  font-family: 'hei35';
  position relative
}

.info {
  width: 100%;
  margin: 100px 0;
}

.section {
  display: flex;
  margin: 0px auto;
  width: 650px;
  border-bottom: 1px solid #bea681;
  padding: 20px 0;
  font-size: 18px;
  position: relative;
}

.section input {
  background: none;
}

.section>div:first-child {
  width: 18%;
  text-align: left;
}

.section>div:nth-child(2) {
  width: 5%;
  text-align: center;
}

.section>div:nth-child(3) {
  width: 75%;
  text-align: left;
}

.section>div:nth-child(3) input {
  width: 100%;
  color: #bea681;
  font-family: 'hei35';
}

.section .shu {
  border-left: 1px solid #bea681;
  height 30px
}

.icon-sousuo {
  font-size: 30px;
  margin-left: -30px;
  color: #bea681;
  cursor: pointer;
}

.icon-xia {
  position: absolute;
  top: 25px;
  right: 30px;
  font-size: 30px;
  color: #bea681;
  cursor: pointer;
}

.btn {
  width: 300px;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  color: #bea681;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #50473a;
  margin-top: 150px;
  cursor: pointer;
}
.succDiv{
    position: absolute;
    top: 230px;
    left: 0;
    right: 0;
    width: 800px;
    margin: 0 auto;
    z-index: 9;
}
.succDiv img{
  width 240px
}
</style>