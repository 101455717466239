import http from './http'
export const requestUrl = 'https://web.princesky.com/'
//店铺列表
export const getStoreList  = params => http(requestUrl+`platform/v1/api/lampowebsite/getStoreList`,params,'get');
//预约
export const saveAppointMent  = params => http(requestUrl+`platform/v1/api/lampowebsite/saveAppointMent`,params,'get');
// 首页视频
export const getWebViedoList  = params => http(requestUrl+`platform/v1/api/lampowebsite/getWebViedoList`,params,'get');
//值域lampo/lampobespoke/lampoone
export const getLookBookList  = params => http(requestUrl+`platform/v1/api/lampowebsite/getLookBookList`,params,'get');
// 新闻
export const getNews  = params => http(requestUrl+`platform/v1/api/lampowebsite/getNews`,params,'get');
// 招聘
export const getJoin  = params => http(requestUrl+`platform/v1/api/lampowebsite/getJoinUsPicList`,params,'get');

