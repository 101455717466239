<template>
  <div class="footer" :class="{fpad:ifPad,lampoEX: title == 'lampolook',oneEX: title == 'onelanalook',}">
      <div class="line" :class="{borColor:color=='gold'}"></div>
      <div class="content">
        <div class="left">
          <div class="logo" @click="toPage('/')">
            <img src="@image/logogold.png" alt="" v-if="color=='gold'">
            <img src="@image/logowhite.png" alt="" v-else>
          </div>
          <div class="section" :class="{gold:color=='gold'}">
            <div>
              <div @click="toPage('group')">
                关于集团
              </div>
              <div @click="toPage('about')">
                品牌简介
              </div>
            </div>
            <div>
              <div @click="toPage('joinus')">
                加入我们
              </div>
              <div @click="toPage('store')">
                门店查询
              </div>
            </div>
            <div>
              <div @click="toPage('contact')">
                联系我们
              </div>
              <div @click="toPage('sewavinglook')">
                定制预约
              </div>
            </div>
          </div>
        </div>
        <div class="right"  v-if="color=='gold'">
          <img src="@image/f4gold.jpg" alt="" @mouseover="showewm(1)" @mouseleave="hideewm" class="f4">
          <img src="@image/f1gold.jpg" alt="" @mouseover="showewm(2)" @mouseleave="hideewm" class="f1">
          <img src="@image/f3gold.jpg" alt="" @mouseover="showewm(3)" @mouseleave="hideewm" class="f3">
          <img src="@image/f2gold.jpg" alt="" @mouseover="showewm(4)" @mouseleave="hideewm" class="f2">
        </div>
        <div class="right"  v-else>
          <img src="@image/f44.png" alt="" @mouseover="showewm(1)" @mouseleave="hideewm" class="f4">
          <img src="@image/f11.png" alt="" @mouseover="showewm(2)" @mouseleave="hideewm" class="f1">
          <img src="@image/f33.png" alt="" @mouseover="showewm(3)" @mouseleave="hideewm" class="f3">
          <img src="@image/f22.png" alt="" @mouseover="showewm(4)" @mouseleave="hideewm" class="f2">
        </div>
      </div>
      <div class="ewm" :class="{ewmShow:ewmShow,r1:now==1,r2:now==2,r3:now==3,r4:now==4}">
        <div>
          <img src="@image/f1.png" alt="" v-if="now==1">
          <img src="@image/f2.png" alt="" v-if="now==2">
          <img src="@image/f3.png" alt="" v-if="now==3">
          <img src="@image/f4.png" alt="" v-if="now==4">
        </div>
      </div>
      <div class="gov"><a href="https://beian.miit.gov.cn/">苏ICP备 17056927号</a></div>
  </div>
</template>
<script>
export default {
  name: "lampo",
  components: {

  },
  props: {
    color:{
      type:String,
      default(){
        return ''
      }
    },
    ifPad:{
      type:Boolean,
      default(){
        return true
      }
    },
    name:{
      type:String,
      default(){
        return 'lampoone'
      }
    },
    title:{
      type:String,
      default(){
        return ''
      }
    },
    
  },
  data() {
    return {
      ewmShow:false,
      now:0
    };
  },
  created(){
  },
  computed: {
   
  },
  mounted() {
    
  },
  watch: {

  },
  methods: {
    toPage(e){
      if(e=='sewavinglook'){
        this.jump({name:e,query:{from:'reserve'}})
        return
      }else if(e==='about'){
        this.jump({name:e,query:{from:this.name}})
        return
      }
        this.jump(e)
    },
    showewm(e){
      this.ewmShow = true
      this.now = e
    },
    hideewm(e){
      this.ewmShow = false
    }
  }
};
</script>
<style scoped>
    .footer{
      min-height: 300px;
      width: 90%;
      margin-left:5%;
      background:#000000;
      min-width: 1200px !important;
      position: relative;
      color: #ffffff;
    }
    .logo{
      cursor: pointer;
    }
    .fpad{
      padding-top:100px;
    }
    .line{
      width: 100%;
      height: 0;
      border-top:1px solid #282828
    }
    .borColor{
      border-top:1px solid #333333 !important
    }
    .content{
      padding:80px 0;
      display: flex;
      justify-content: space-between;
      height: 200px;
    }
    .left,.right{
      display: flex;
      align-items: center;
    }
    .right{
      margin-right: -14px;
    }
    .right img{
      margin-right: 20px;
      cursor: pointer;
    }
    .right .f1{
      width: 85px;
    }
    .right .f2{
      width: 60px;
    }
    .right .f3{
      width: 80px;
    }
    .right .f4{
      width: 65px;
    }
    .right img:hover{
      opacity: .6;
    }
    .right img:last-child{
      margin-right: 0;
    }
    .logo{
      width: 48px;
      height:77px
    }
    .logo img{
      height:100%;
    }
    .section{
      display: flex;
    }
    .gold{
      color:#bea681
    }
    .section>div{
      margin-left:66px;
      font-weight: 600;
      font-size: 12px;
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .section>div>div{
      cursor: pointer;
    }
    .section>div>div:hover{
      color:#666
    }
    .ewm{
      width: 120px;
      height: 120px;
      padding: 10px;
      position: absolute;
      background: url(http://www.princesky.com/images/alpha.000.60.png);
      text-align: center;
      bottom: 210px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      transition: all .5s;
      transform: scale(0);
      opacity: 0;
    }
    .r4{
      right:-50px
    }
    .r3{
      right: 40px;
    }
    .r2{
      right: 140px;
    }
    .r1{
      right: 235px;
    }
    .ewmShow{
      transform: scale(1);
      opacity: 1;
    }
    .ewm div{
      text-align: center;
      color: #fff;
      line-height: 20px;
      font-size: 12px;
      margin-top:15px
    }
    .ewm div img{
      width: 90px;
    }
    .gov{
      font-family : "阿里巴巴普惠体";
      position: absolute;
      right: 0;
      bottom: 100px;
      color:#000
    }
    .gov a{
      color:#000;
      font-weight: 500;
      font-size: 12px;
    }

    .lampoEX{
      background-color: #1b2244;
    }

    .oneEX{
      background-color: #d9d9d9;
    }
</style>