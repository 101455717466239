<template>
  <div class="lampoindex" :style="{ background: $route.meta.backColor }">
    <my-icon :listColor="$route.meta.listColor" />
    <my-section
      :color="$route.meta.color"
      :listColor="$route.meta.listColor"
      :exc="$route.meta.exc"
      :topShow="$route.meta.topShow"
      :title="$route.meta.title"
      :titleLists="titleLists"
      @changeYear = 'changeYear'
    />
    <keep-alive v-if="$route.meta.isAlive">
      <router-view :nowLists="nowLists" />
    </keep-alive>
    <router-view :nowLists="nowLists" v-else />
    <my-footer :color="$route.meta.listColor" :ifPad="$route.meta.ifPad" :name="$route.name" :title="$route.meta.title"/>
  </div>
</template>
<script>
import MyFooter from "../../components/common/myFooter.vue";
import { getLookBookList, requestUrl } from "server/test";
import { mapGetters,mapMutations } from "vuex";
export default {
  name: "lampo",
  components: {
    MyFooter,
  },
  props: {

  },
  data() {
    return {
      nowLists: {id:1},
      content:[],
      titleLists:[],
      nowIndex : 0
    };
  },
  created() {},
  computed: {
    ...mapGetters(["city"])
  },
  watch: {
    $route(val, oldval) {
      if(val.meta.par){
        this.getContent(val.meta.par)
      }
    },
  },
  mounted() {
    this.changeCity('常州')
    if(this.$route.meta.par){
      this.getContent(this.$route.meta.par)
    }
  },
  methods: {
    async getContent(e) {
      try {
        let params = {
          brand: e
        };
        let res = await getLookBookList(params);
        let array = [];
        let titleLists = []
        array.push(...res.data);
        array.forEach((item) => {
          item.picUrl = item.picUrl.split(",");
          titleLists.push(item.title)
        });
        this.content = array;
        this.nowLists = array[this.nowIndex];
        this.titleLists = titleLists
      } catch (error) {
        console.log(error);
      }
    },
    changeYear(e){
      if(this.nowLists != e){
        this.nowIndex = e
        this.nowLists = this.content[e];
      }
    },
    ...mapMutations(['changeCity'])
  },
};
</script>
<style scoped>
.lampoindex {
  width: 100%;
  min-height: 100vh;
}
</style>